/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

// DO NOT MODIFY
// FILE GENERATED BY `intl-codegen@2.2.1`
// https://github.com/eversport/intl-codegen
// DO NOT MODIFY

import { defineLoader } from "intl-codegen/runtime";

export const loadLanguage = defineLoader([
  "acceptFriendRequest",
  "accessCode",
  "activityDetailAvailabilityLabel",
  "activityDetailCancellationPolicyHeader",
  "activityDetailCorporatePriceLabel",
  "activityDetailDateLabel",
  "activityDetailEarlyBirdPriceLabel",
  "activityDetailEventType",
  "activityDetailLastMinutePriceLabel",
  "activityDetailLocationLabel",
  "activityDetailMembershipPriceLabel",
  "activityDetailMembershipPromotionLabel",
  "activityDetailMultiSessionStartDate",
  "activityDetailMultiSessionStartedOnDate",
  "activityDetailMultiSessionsLabel",
  "activityDetailOnlineLabel",
  "activityDetailOnlineStreamHeader",
  "activityDetailOnlineStreamInstructionsStep1",
  "activityDetailOnlineStreamInstructionsStep2",
  "activityDetailOnlineStreamInstructionsStep3",
  "activityDetailOnlineStreamProviderInstructions",
  "activityDetailOnlineStreamSubtext",
  "activityDetailPricingOptionsHeading",
  "activityDetailProductTablePriceHeader",
  "activityDetailProductTablePricingOptionHeader",
  "activityDetailProductTablePurchaseOptionHeader",
  "activityDetailProductTableTicketOptionHeader",
  "activityDetailProductsHeading",
  "activityDetailProductsShowMore",
  "activityDetailReviewsShowMore",
  "activityDetailScheduleHeading",
  "activityDetailScheduleSubheading",
  "activityDetailScheduleTableDateHeader",
  "activityDetailScheduleTableLocationHeader",
  "activityDetailScheduleTableTeacherHeader",
  "activityDetailScheduleTableTimeHeader",
  "activityDetailTicketOptionsHeading",
  "activityDetailTicketPastSessionsLabel",
  "activityDetailTicketScheduleHeading",
  "activityDetailTicketUpcomingSessionsLabel",
  "activityDetailTimeLabel",
  "activityDetailTrialPriceLabel",
  "activityDetailsAlreadyRegistered",
  "activityDetailsBookNow",
  "activityDetailsBookNowFrom",
  "activityDetailsCalendarDescription",
  "activityDetailsCalendarSummary",
  "activityDetailsDifficultyHeader",
  "activityDetailsEventAlreadyOccuredLabel",
  "activityDetailsEventBookableFromLabel",
  "activityDetailsEventFullyBookedLabel",
  "activityDetailsEventIsCancelledLabel",
  "activityDetailsGenericAvailabilityRemaining",
  "activityDetailsLanguageHeader",
  "activityDetailsLocationHeader",
  "activityDetailsMembershipPrice",
  "activityDetailsMetaDescription",
  "activityDetailsMultipleLocations",
  "activityDetailsNotBookable",
  "activityDetailsOnlineLocation",
  "activityDetailsRoomHeader",
  "activityDetailsSpecificAvailabilityRemaining",
  "activityDetailsTeacherHeading",
  "activityDetailsTeacherLabel",
  "activityDetailsTrainerDescription",
  "activityDetailsTrainerEducation",
  "activityDetailsTrainerHeading",
  "activityDetailsTrainerLabel",
  "activityDetailsUnlimitedAvailability",
  "activityDetailsUrgencyLimitedSpots",
  "activityDetailsVenueInformationHeader",
  "activityDetailsVenueReviewsHeader",
  "activityDifficultyAllLevels",
  "activityDifficultyAllLevelsDescription",
  "activityDifficultyBeginner",
  "activityDifficultyBeginnerDescription",
  "activityDifficultyIntermediate",
  "activityDifficultyIntermediateDescription",
  "activityDifficultyNotSpecified",
  "activityDifficultyProfessional",
  "activityDifficultyProfessionalDescription",
  "activityDurationInMinutesShort",
  "activityEventTypeCamp",
  "activityEventTypeClass",
  "activityEventTypeCourse",
  "activityEventTypeEducation",
  "activityEventTypeEvent",
  "activityEventTypeOpenTraining",
  "activityEventTypeRetreat",
  "activityEventTypeTraining",
  "activityEventTypeWorkshop",
  "activitySingleSessionSharingMessage",
  "addFriend",
  "addNewCommentPlaceholder",
  "addToCalendarAltText",
  "addToFriendsButtonText",
  "addVenueToFavorites",
  "allPublicMatchesInCityCallToAction",
  "allPublicMatchesInCityLink",
  "allSportsHeading",
  "anotherUserProfileSharingMessage",
  "approveFriendRequest",
  "approveFriendRequestButtonText",
  "assumptionTestFriendsConnectionBetaTesterButton",
  "assumptionTestFriendsConnectionBetaTesterText",
  "assumptionTestFriendsConnectionSubTitle",
  "assumptionTestFriendsConnectionTitle",
  "assumptionTestFriendsUserProfileAddToFriendsButton",
  "back",
  "backHome",
  "backLink",
  "backToHome",
  "backToProfile",
  "backToUserProfile",
  "badminton",
  "barre",
  "bauchBeinePo",
  "bookableSportsInCityHeading",
  "bookingStateAccepted",
  "bookingStateCancelled",
  "bookingStateMoved",
  "bookingStateRejected",
  "bookingStateRequested",
  "boxen",
  "branchesModalCancel",
  "branchesModalOtherLocations",
  "branchesSectionSubtitle",
  "branchesSectionTitle",
  "branchesViewOtherLocations",
  "breadcrumbNavigationInvoiceHistory",
  "breadcrumbNavigationItemHome",
  "breadcrumbNavigationItemNotifications",
  "breadcrumbNavigationItemPassesAndMemberships",
  "breadcrumbNavigationItemUserFriendProfile",
  "breadcrumbNavigationItemUserFriends",
  "breadcrumbNavigationItemUserProfile",
  "breadcrumbNavigationItemUserPublicProfile",
  "breadcrumbsCalendar",
  "breadcrumbsDiscoverSportMatchesInCity",
  "breadcrumbsMyBookings",
  "breadcrumbsVenueProfileMatches",
  "campOfferingHeading",
  "cancelMatch",
  "cancelMatchAndBooking",
  "cancelModalConfirmCancel",
  "cancelModalInformation",
  "cancellationConditionsHeaderForOrganizers",
  "classesAndTrainingsOfferingHeading",
  "classesOfferingHeading",
  "clickHereTo",
  "commentsSectionNotificationsTitle",
  "commentsSectionTitle",
  "commentsSectionViewMoreComments",
  "confirmationDisclaimerReceivedFriendshipRequestDeclineRequest",
  "confirmationDisclaimerReceivedFriendshipRequestDescription",
  "confirmationDisclaimerReceivedFriendshipRequestDismiss",
  "confirmationDisclaimerReceivedFriendshipRequestTitle",
  "confirmationDisclaimerRemoveFriendship",
  "confirmationDisclaimerRemoveFriendshipDescription",
  "confirmationDisclaimerRemoveFriendshipDismiss",
  "confirmationDisclaimerRemoveFriendshipTitle",
  "confirmationDisclaimerRequestedFriendshipCancelRequest",
  "confirmationDisclaimerRequestedFriendshipDescription",
  "confirmationDisclaimerRequestedFriendshipDismiss",
  "confirmationDisclaimerRequestedFriendshipTitle",
  "connectToSeeMoreContent",
  "continueToMyBookings",
  "cookieBannerAccept",
  "cookieBannerConfirm",
  "cookieBannerCookiePreferences",
  "cookieBannerDescriptionLong",
  "cookieBannerDescriptionShort",
  "cookieBannerFurtherInformation",
  "cookieBannerLabelAdvertising",
  "cookieBannerLabelAdvertisingDescription",
  "cookieBannerLabelAnalytics",
  "cookieBannerLabelAnalyticsDescription",
  "cookieBannerLabelNecessary",
  "cookieBannerLabelNecessaryDescription",
  "cookieBannerPrivacyPolicy",
  "cookieBannerTechnicalNotes",
  "cookieBannerTitle",
  "corporateDealLabel",
  "corporateDealModalSubheading",
  "corporatePartnerPromoText",
  "corporatePartnerPromoTextLink",
  "coursesOfferingHeading",
  "coursesOfferingHeadingV2",
  "courtSlotsNothingAvailableOnTheDay",
  "createYourMatchCardStep1",
  "createYourMatchCardStep2",
  "createYourMatchCardSubTitle",
  "createYourMatchCardTitle",
  "crossfit",
  "dataSubProcessorsHeading",
  "dateUnitDay",
  "dateUnitDayPlural",
  "dateUnitGame",
  "dateUnitGamePlural",
  "dateUnitHour",
  "dateUnitHourPlural",
  "dateUnitMinute",
  "dateUnitMinutePlural",
  "dateUnitMonth",
  "dateUnitMonthPlural",
  "dateUnitSeason",
  "dateUnitSeasonPlural",
  "dateUnitUnit",
  "dateUnitUnitPlural",
  "dateUnitWeek",
  "dateUnitWeekPlural",
  "dateUnitWeekend",
  "dateUnitWeekendPlural",
  "dateUnitYear",
  "dateUnitYearPlural",
  "dateTimeRelativeToNow",
  "dayOfTheWeek",
  "discoverNoResultsCity",
  "discoverNoResultsDescription",
  "discoverNoResultsLivestreams",
  "discoverNoResultsOnlineOfferingsLabel",
  "discoverNoResultsPopularCitiesLabel",
  "discoverNoResultsPopularSportsLabel",
  "discoverNoResultsSportCity",
  "discoverNoResultsVideos",
  "dismissAction",
  "dismissPlayersRemovalButton",
  "downloadAppDescription",
  "downloadAppTitle",
  "downloadInvoiceIsGeneratingLabel",
  "downloadInvoiceLabel",
  "downloadProgressPleaseCheckBackSoon",
  "editProfilePrivacyCallToAction",
  "educationOfferingHeading",
  "emptyListResultDescription",
  "emptyListResultTitle",
  "eventByLabel",
  "eventsOfferingHeading",
  "eversports",
  "fakeNotificationHeader1",
  "fakeNotificationHeader2",
  "faqTypeExtraInfoToKnow",
  "faqTypeWhatToKnowAboutCancelling",
  "faqTypeWhatToKnowAboutNeededEquipment",
  "faqTypeWhatToKnowForFirstVisit",
  "faqTypeWhyVisitThisVenue",
  "filtersActivitiesAvailabilityAfternoon",
  "filtersActivitiesAvailabilityEvening",
  "filtersActivitiesAvailabilityHeading",
  "filtersActivitiesAvailabilityMorning",
  "filtersAmenitiesHeading",
  "filtersCourtAllSelected",
  "filtersCourtAreaHeading",
  "filtersCourtAreaIndoor",
  "filtersCourtAreaOutdoor",
  "filtersCourtSurfaceHeading",
  "filtersCourtSurfaceTypeArtificialGrass",
  "filtersCourtSurfaceTypeArtificialIce",
  "filtersCourtSurfaceTypeAsh",
  "filtersCourtSurfaceTypeAsphalt",
  "filtersCourtSurfaceTypeCarpet",
  "filtersCourtSurfaceTypeCarpetGranule",
  "filtersCourtSurfaceTypeConcrete",
  "filtersCourtSurfaceTypeFelt",
  "filtersCourtSurfaceTypeGranuale",
  "filtersCourtSurfaceTypeGrass",
  "filtersCourtSurfaceTypeHardCourt",
  "filtersCourtSurfaceTypeIce",
  "filtersCourtSurfaceTypeOpticourt",
  "filtersCourtSurfaceTypeParquet",
  "filtersCourtSurfaceTypePvc",
  "filtersCourtSurfaceTypeRedCourt",
  "filtersCourtSurfaceTypeSand",
  "filtersCourtSurfaceTypeSynthetic",
  "filtersCourtSurfaceTypeTartan",
  "filtersCourtSurfaceTypeWater",
  "filtersMatchesCompetitionTypeHeading",
  "filtersMatchesLevelHeading",
  "filtersMatchesSportsHeading",
  "filtersMatchesVenuesHeading",
  "filtersReset",
  "filtersResetAll",
  "filtersReviewCountHeading",
  "filtersStudioActivitiesHeading",
  "filtersStudioActivitiesIndoorActivity",
  "filtersStudioActivitiesLivestream",
  "filtersStudioActivitiesOutdoorActivity",
  "filtersStudioActivitiesVideo",
  "filtersStudioAllSelected",
  "filtersStudioAttributesCorporateDiscount",
  "filtersStudioAttributesFreeTrial",
  "filtersStudioAttributesHeading",
  "filtersStudioAttributesIntroOffer",
  "filtersStudioAttributesNewVenue",
  "filtersSubSportsHeading",
  "filtersSubSportsSearchLabel",
  "findCorporateDealsOnVenueProfile",
  "findFriendsSectionSubTitle",
  "findFriendsSectionTitle",
  "findFriendsTab",
  "findNewFriendsSearchPlaceholder",
  "findNewFriendsTitle",
  "fitness",
  "footerAboutUs",
  "footerBlog",
  "footerCancelMembership",
  "footerCareer",
  "footerContactUs",
  "footerCookieSettings",
  "footerCopyrightDisclaimer",
  "footerCorporateDescription",
  "footerCorporateTitle",
  "footerDataProtection",
  "footerDemo",
  "footerForCorporates",
  "footerHeaderContact",
  "footerHeaderEversportsManager",
  "footerHeaderMoreOfEversports",
  "footerHeaderSocialMedia",
  "footerImpressum",
  "footerPopularSportsInCity",
  "footerPricing",
  "footerProviderDescription",
  "footerProviderTitle",
  "footerSecurity",
  "footerSportInCity",
  "footerTermsAndConditions",
  "footerVisitTheWebsite",
  "forbiddenErrorMessage",
  "forbiddenPageDescription",
  "forbiddenPageTitle",
  "friendRequestBadge",
  "friends",
  "friendsActivitiesTab",
  "friendsAttendingAddFriendsLearnMore",
  "friendsAttendingPromotionAdvantage1",
  "friendsAttendingPromotionAdvantage2",
  "friendsAttendingPromotionAdvantage3",
  "friendsAttendingPromotionButton",
  "friendsAttendingPromotionClassCalendarImageDescription",
  "friendsAttendingPromotionProfileImageDescription",
  "friendsAttendingPromotionSubtitle",
  "friendsAttendingPromotionText",
  "friendsAttendingPromotionTitle",
  "friendsAttendingSectionTitle",
  "friendsButtonText",
  "friendsCount",
  "friendsOfUserTitle",
  "friendsPageMetaDescription",
  "friendsPageMetaTitle",
  "friendsPrivacyAdjustPrivacySettingsLink",
  "friendsPrivacyUpdateConfirmationButton",
  "friendsPrivacyUpdateParagraph1",
  "friendsPrivacyUpdateParagraph2",
  "friendsPrivacyUpdateParagraph3",
  "friendsPrivacyUpdateParagraph4",
  "friendsPrivacyUpdatePrivacyPolicyLink",
  "friendsPrivacyUpdateTitle",
  "friendsRequestsSectionNoRequestsDescription",
  "friendsRequestsSectionNoRequestsTitle",
  "friendsRequestsTab",
  "friendsSectionTitle",
  "friendsTab",
  "friendshipRequested",
  "funktionellesTraining",
  "fussball",
  "guestDisclaimerSectionButton",
  "guestDisclaimerSectionDescription",
  "guestDisclaimerSectionTitle",
  "guestJoinMatchButton",
  "hasFriendsNotificationPromotionText",
  "hasFriendsNotificationPromotionTitle",
  "headerCorporateBenefitsBudgetLeftAmount",
  "headerCorporateBenefitsBudgetLeftLabel",
  "headerCorporateBenefitsLabel",
  "headerCorporateBenefitsUnlimitedBudget",
  "headerForBusinesses",
  "headerForCorporates",
  "headerLoggedInUser",
  "headerLogin",
  "headerLoginOrRegister",
  "headerRegister",
  "hiit",
  "homeBrandPromise1Description",
  "homeBrandPromise1Header",
  "homeBrandPromise2Description",
  "homeBrandPromise2Header",
  "homeBrandPromise3Description",
  "homeBrandPromise3Header",
  "homeCantFindWhatYoureLookingFor",
  "homeCitySportSeoLinksCardHeader",
  "homeCitySportSeoLinksDescription",
  "homeCitySportSeoLinksHeader",
  "homeDoYouOwnAVenueThatsNotOnEversports",
  "homeDownloadTheEversportsApp",
  "homeFeaturePreviewDescription",
  "homeFeaturePreviewFriendsFeedDescription",
  "homeFeaturePreviewFriendsFeedTitle",
  "homeFeaturePreviewHeader",
  "homeFeaturePreviewUpdatesDescription",
  "homeFeaturePreviewUpdatesTitle",
  "homeFeaturePreviewWaitingListDescription",
  "homeFeaturePreviewWaitingListTitle",
  "homeHeader",
  "homeHeaderFocusCities",
  "homeHeaderFocusSports",
  "homeHeaderOnlineOfferings",
  "homeHeroImageAltText",
  "homeLabel",
  "homeLearnAboutTheManager",
  "homeLearnMore",
  "homeLiveOnlineButton",
  "homeLiveOnlineDescription",
  "homeLiveOnlineTitle",
  "homeMetaDescription",
  "homeMetaTitle",
  "homeOnDemandButton",
  "homeOnDemandDescription",
  "homeOnDemandTitle",
  "homeProviderPromoDescription",
  "homeProviderPromoTitle",
  "homeRebrandAllSportsInMyCity",
  "homeRebrandCheckOutOfferingsFor",
  "homeRebrandDownloadAppDescription",
  "homeRebrandDownloadAppTitle",
  "homeRebrandHeader",
  "homeRebrandNoOfferings",
  "homeRebrandSelectACity",
  "homeRebrandSelectASport",
  "homeRebrandSportOfferingsDescription",
  "homeRebrandSportOfferingsHeader",
  "homeRebrandSubheader",
  "homeRebrandTestimonialsDescription",
  "homeRebrandTestimonialsHeader",
  "homeRegisterToLearnAboutTheManager",
  "homeSportActivitiesInCity",
  "homeStayActiveAndBookSports",
  "homeSubheader",
  "homeSubheaderFocusCities",
  "homeSubheaderFocusSports",
  "homeSubheaderOnlineOfferings",
  "homeSuggestAVenue",
  "homeSuggestAVenueAndReceiveAVoucher",
  "homeTldMetaDescription",
  "homeTldMetaTitle",
  "homeViewOnMap",
  "homeWhatIsEversportsDescription",
  "homeWhatIsEversportsHeader",
  "homeWhereIsEversportsDescription",
  "homeWhereIsEversportsHeader",
  "homepageMyFriendsButton",
  "inCityLabel",
  "infoToolTipAltText",
  "inviteFriendsLoadMore",
  "inviteFriendsNoFriendsButton",
  "inviteFriendsNoFriendsDescription",
  "inviteFriendsNoFriendsTitle",
  "inviteFriendsSendNotificationButton",
  "inviteFriendsTitle",
  "invoiceHistoryPageHeader",
  "invoiceHistoryTableHeaderDate",
  "invoiceHistoryTableHeaderDownloadInvoices",
  "invoiceHistoryTableHeaderPaymentAmount",
  "invoiceHistoryTableHeaderPaymentMethod",
  "invoiceHistoryTableHeaderPaymentStatus",
  "invoiceHistoryTableTextNoInvoices",
  "invoiceStatusCancelled",
  "invoiceStatusPaid",
  "invoiceStatusRefunded",
  "invoiceStatusUnpaid",
  "joinedMatchDisclaimerBookingsLinkText",
  "joinedMatchDisclaimerButton",
  "joinedMatchDisclaimerDescription",
  "joinedMatchDisclaimerTitle",
  "learnMoreAboutEversportsCorporate",
  "listToggleButton",
  "listingOverviewClasses",
  "listingOverviewCurrentlyViewing",
  "listingOverviewMore",
  "listingOverviewStudios",
  "listingResultCount",
  "listingResultOnlineOnlyVenue",
  "livestreamsOfferingHeading",
  "loadMore",
  "loadMoreFriendsButton",
  "loadMoreNotificationsButton",
  "loadMoreSuggestions",
  "loadMoreUpcomingFriendsParticipationsButton",
  "locationOnMapAltText",
  "loggedInUserJoinMatchButton",
  "loggedInUserMatchDisclaimerDontForget",
  "loggedInUserMatchDisclaimerSubtitle",
  "loggedInUserMatchDisclaimerTitle",
  "loginOrRegisterDisclaimerDescription",
  "managerPromoButtonText",
  "managerPromoSubtitle",
  "managerPromoTitle",
  "mapGeolocationNotFoundErrorMessage",
  "mapToggleButton",
  "matchAdditionalActionsOptions",
  "matchBookingPageTitle",
  "matchCourtAreaIndoor",
  "matchCourtAreaOutdoor",
  "matchCourtSurfaceTypeArtificialGrass",
  "matchCourtSurfaceTypeArtificialIce",
  "matchCourtSurfaceTypeAsh",
  "matchCourtSurfaceTypeAsphalt",
  "matchCourtSurfaceTypeCarpet",
  "matchCourtSurfaceTypeCarpetGranule",
  "matchCourtSurfaceTypeConcrete",
  "matchCourtSurfaceTypeFelt",
  "matchCourtSurfaceTypeGranuale",
  "matchCourtSurfaceTypeGrass",
  "matchCourtSurfaceTypeHardCourt",
  "matchCourtSurfaceTypeIce",
  "matchCourtSurfaceTypeOpticourt",
  "matchCourtSurfaceTypeParquet",
  "matchCourtSurfaceTypePvc",
  "matchCourtSurfaceTypeRedCourt",
  "matchCourtSurfaceTypeSand",
  "matchCourtSurfaceTypeSynthetic",
  "matchCourtSurfaceTypeTartan",
  "matchCourtSurfaceTypeWater",
  "matchDurationInMinutesShort",
  "matchIsFull",
  "matchNotFoundCallToAction",
  "matchOverviewBannerSubtitle",
  "matchOverviewBannerTitle",
  "matchParticipantsCount",
  "matchParticipantsWithCountWithMaxParticipants",
  "matchParticipationRemoveButton",
  "matchParticipationSuccessfulMessage",
  "matchPromotionAdvantage1",
  "matchPromotionAdvantage2",
  "matchPromotionAdvantage3",
  "matchPromotionAdvantagesTitle",
  "matchPromotionConfirmationButton",
  "matchRefundMessage",
  "matchRejectedMessage",
  "matchRuleCompetitionTypeCompetitive",
  "matchRuleCompetitionTypeCompetitiveDescription",
  "matchRuleCompetitionTypeFriendly",
  "matchRuleCompetitionTypeFriendlyDescription",
  "matchRuleCompetitionTypePlaceholder",
  "matchRuleCompetitionTypeTitle",
  "matchRuleGenderFemaleOnly",
  "matchRuleGenderMaleOnly",
  "matchRuleGenderPlaceholder",
  "matchRuleGenderTitle",
  "matchRuleGenderUnisex",
  "matchRuleLevelAdvanced",
  "matchRuleLevelAdvancedDescription",
  "matchRuleLevelAny",
  "matchRuleLevelAnyDescription",
  "matchRuleLevelBeginner",
  "matchRuleLevelBeginnerDescription",
  "matchRuleLevelIntermediate",
  "matchRuleLevelIntermediateDescription",
  "matchRuleLevelPlaceholder",
  "matchRuleLevelTitle",
  "matchRuleMaxNumberOfPlayers",
  "matchRulesCallToAction",
  "matchRulesFillOutRequiredFields",
  "matchSharingMessage",
  "matchThankYouBannerSubtitleBookingConfirmed",
  "matchThankYouBannerSubtitleBookingRejected",
  "matchThankYouBannerSubtitleBookingRequested",
  "matchThankYouBannerTitleBookingCancelled",
  "matchThankYouBannerTitleBookingConfirmed",
  "matchThankYouBannerTitleBookingPlanned",
  "matchThankYouBannerTitleBookingRejected",
  "matchThankYouBannerTitleBookingRequested",
  "matchThankYouPageTitle",
  "matchVisibilityBadgePrivate",
  "matchVisibilityBadgePublic",
  "matchVisibilityToggleLabel",
  "matchVisibilityToggleLabelSubtitle",
  "meditation",
  "mobileFiltersApplyFilters",
  "mobileFiltersCancel",
  "mobileFiltersHeading",
  "mobileFiltersReset",
  "mobileFiltersToggleButton",
  "monthOfTheYear",
  "moreSlotsLabel",
  "navigationTabNewTag",
  "next",
  "no",
  "noCommentsPlaceholderText",
  "noFriendsNotificationPromotionText",
  "noFriendsNotificationPromotionTitle",
  "noFriendsUpcomingParticipationsAddFriendsButton",
  "noFriendsUpcomingParticipationsDescription",
  "noFriendsUpcomingParticipationsInviteToBookingsButton",
  "noFriendsUpcomingParticipationsTitle",
  "noMatchesFound",
  "noMatchesFoundDescription",
  "noMatchesFoundTitle",
  "noMatchesHowToCreateAPublicMatch",
  "noMatchesViewCourtSchedule",
  "noNotificationsDescription",
  "noNotificationsTitle",
  "noNumberOfPlayersSet",
  "noSpotsLeft",
  "nonLoggedInUserCommentDisclaimer",
  "notFoundPageDescription",
  "notFoundPageTitle",
  "notificationBannerDescription",
  "notificationBannerTitle",
  "notificationCenterTooltipDescription",
  "notificationCenterTooltipTitle",
  "notificationPromotionDialogAddFriends",
  "notificationPromotionDialogBack",
  "notificationPromotionDialogInviteFriends",
  "notifications",
  "notificationsMetaDescription",
  "notificationsMetaTitle",
  "notificationsPageTitle",
  "npsCommentButton",
  "npsCommentEditScoreButton",
  "npsCommentPlaceholder",
  "npsCommentTitle",
  "npsScoreButton",
  "npsScoreButtonNoNumberSelected",
  "npsScoreExtremelyLikely",
  "npsScoreNotAtAllLikely",
  "npsScoreThankYouMessage",
  "npsScoreTitle",
  "numberOfListingResultsFound",
  "numberOfOpenSpots",
  "onlineOnlyVenueDescription",
  "onlineOnlyVenueHeading",
  "onlyBookable14DaysAhead",
  "openGoogleMaps",
  "openGoogleMapsShortText",
  "openMaps",
  "openTrainingsOfferingHeading",
  "organizerMatchDisclaimerNextStep",
  "organizerMatchDisclaimerSubtitle",
  "organizerMatchDisclaimerTitle",
  "organizerNotePlaceholder",
  "organizerNoteSubtitleAdmin",
  "organizerNoteTitleAdmin",
  "organizerNoteTitleNonAdmin",
  "organizerSharingButton",
  "overallPrice",
  "padel",
  "paginationPageLabel",
  "participantMatchDisclaimerAllSet",
  "participantMatchDisclaimerSubtitle",
  "participantMatchDisclaimerTitle",
  "participationRulesTitle",
  "participationStateBooked",
  "participationStateJoined",
  "paymentUnitEuro",
  "paymentServiceTypeBancontact",
  "paymentServiceTypeCash",
  "paymentServiceTypeChequecard",
  "paymentServiceTypeChequevacance",
  "paymentServiceTypeCredit",
  "paymentServiceTypeCreditcard",
  "paymentServiceTypeCreditcardOtc",
  "paymentServiceTypeDebitcard",
  "paymentServiceTypeEps",
  "paymentServiceTypeGiropay",
  "paymentServiceTypeIdeal",
  "paymentServiceTypeManual",
  "paymentServiceTypeMarketingDiscount",
  "paymentServiceTypePaypal",
  "paymentServiceTypeSepa",
  "paymentServiceTypeSepaOnline",
  "paymentServiceTypeSofort",
  "paymentServiceTypeTransfer",
  "paymentServiceTypeTwint",
  "paymentServiceTypeTwintOtc",
  "paymentServiceTypeVoucher",
  "paymentServiceTypeVoucherOtc",
  "paymentStateOpen",
  "paymentStateSucceeded",
  "paymentStatusPaid",
  "paymentStatusUnpaid",
  "pilates",
  "poledance",
  "pricePerPlayer",
  "privacySettingButtonVisibility",
  "privateMatchToggledSnackbarMessage",
  "privateProfileDescription",
  "privateProfilePageDescription",
  "privateProfilePageTitle",
  "privateProfileTitle",
  "profilePrivacyApplyButton",
  "profilePrivacyDismissButton",
  "profilePrivacyHideProfileDescription",
  "profilePrivacyHideProfileText",
  "profilePrivacySettingsDescription",
  "profilePrivacySettingsTitle",
  "publicMatchCreatedDisclaimerButton",
  "publicMatchCreatedDisclaimerDescription",
  "publicMatchCreatedDisclaimerMatchesLinkText",
  "publicMatchCreatedDisclaimerTitle",
  "publicMatchOfferingHeading",
  "publicMatchesAdvantage1",
  "publicMatchesAdvantage2",
  "publicMatchesAdvantage3",
  "publicMatchesConfirmationButton",
  "publicMatchesPricePerPlayer",
  "publicMatchesPromotionAdvantagesSubTitle",
  "publicMatchesPromotionAdvantagesTitle",
  "publicMatchesSectionTitle",
  "publicUserProfileMetaDescription",
  "publicUserProfileMetaTitle",
  "ratingNoReviews",
  "ratingReviewCount",
  "ratingTypeAtmosphere",
  "ratingTypeCleanliness",
  "ratingTypeFriendlinessAndService",
  "ratingTypePricePerformance",
  "ratingTypeRoomQuality",
  "receivedFriendRequestsTitle",
  "recentTeachersHeader",
  "recentTeachersPlaceholder",
  "recentTeachersSubHeader",
  "recentTeachersTeacherCardButtonClassSchedule",
  "recentTeachersTeacherCardButtonNoClassesAvailable",
  "recentTeachersTeacherCardButtonVenueOverview",
  "recommendations",
  "recommendationsBannerLabelDiscoverOffers",
  "recommendationsBannerLabelRecentTeachers",
  "recommendationsHeroSubtitle",
  "recommendationsHeroTitle",
  "recommendationsMetaDescription",
  "recommendationsMetaTitle",
  "recommendationsPageTitle",
  "removePlayersAction",
  "removePlayersButton",
  "removePlayersTitle",
  "requestedFriendshipButtonText",
  "resetParticipationRule",
  "retreatsOfferingHeading",
  "rueckenfit",
  "saveRulesAndContinue",
  "saveToCalendar",
  "savedOrganizerNote",
  "schwimmen",
  "searchCancelButtonText",
  "searchCategoryActivitiesInYourCity",
  "searchCategoryVenuesAndStudios",
  "searchCategoryVenuesInYourCity",
  "searchClearButtonTitle",
  "searchCloseMobileDrawer",
  "searchCurrentLocation",
  "searchForLocationLabel",
  "searchForSport",
  "searchForSportOrVenueLabel",
  "searchFriendsSubTitle",
  "searchFriendsTitle",
  "searchGeolocationNotFoundErrorMessage",
  "searchInputPlaceholderText",
  "searchInventoryPlaceholderDesktop",
  "searchInventoryPlaceholderMobile",
  "searchNoResults",
  "searchPlaceholder",
  "searchSportInCity",
  "searchSubmitButtonText",
  "searchSuggestedResultsText",
  "searchVenuesInCity",
  "searchViewAllSportsInCity",
  "searchViewVenuesAndStudios",
  "seeLessContent",
  "seeMoreContent",
  "seeMoreSports",
  "sentFriendRequestsTitle",
  "settingsButton",
  "shareActivityDetail",
  "shareIt",
  "shareMatch",
  "shareUserProfileButton",
  "shareVenueProfile",
  "shareWithFriends",
  "shareYourProfile",
  "shareYourProfileCallToAction",
  "shareYourProfileDescription",
  "sharingActionsCopyLink",
  "sharingActionsCopyText",
  "sharingActionsEmail",
  "sharingActionsMessage",
  "sharingActionsWhatsapp",
  "similarSportsInCityHeading",
  "sportInCity",
  "sportTagSportInCity",
  "sporttagsSectionShowLessResults",
  "sporttagsSectionShowMoreResults",
  "squash",
  "storeBadgeAppleStore",
  "storeBadgeGetItOn",
  "storeBadgeGooglePlay",
  "stretching",
  "studiosHeading",
  "suggestedFriendJoinedActivities",
  "suggestedFriendMutualFriends",
  "suggestedFriendsShowAll",
  "suggestedFriendsTitle",
  "syncContactsSubTitle",
  "syncContactsTitle",
  "tanzen",
  "technicalNotesHeading",
  "tennis",
  "testimonialAlexisAustria",
  "testimonialCamilleNetherlands",
  "testimonialClaudiaNetherlands",
  "thisProfileInformationIsOnlyVisibleToConnectedUsers",
  "tldCountryName",
  "today",
  "tomorrow",
  "tooltipCopiedLink",
  "tooltipCopiedText",
  "trainingsOfferingHeading",
  "trialOffersHasMore",
  "trialOffersSubtitle",
  "trialOffersTitle",
  "unexpectedErrorDescription",
  "unexpectedErrorMessage",
  "unlimitedPlayers",
  "userConnectionTooltipFriendsOfUserDescription",
  "userConnectionTooltipFriendsOfUserTitle",
  "userConnectionTooltipShareYourProfileDescription",
  "userConnectionTooltipShareYourProfileTitle",
  "userConnectionTooltipUpcomingBookingsDescription",
  "userConnectionTooltipUpcomingBookingsTitle",
  "userFriendsPageMetaDescription",
  "userFriendsPageMetaTitle",
  "userInterviewRegistrationEnglish",
  "userInterviewRegistrationGerman",
  "userInterviewRegistrationMetaDescription",
  "userInterviewRegistrationMetaTitle",
  "userInterviewRegistrationTitle",
  "userMenuDrawerWelcomeWithComma",
  "userMenuItemForBusinesses",
  "userMenuItemForCorporates",
  "userMenuItemHome",
  "userMenuItemLogin",
  "userMenuItemLoginOrRegister",
  "userMenuItemMyBookings",
  "userMenuItemMyFavorites",
  "userMenuItemMyFriends",
  "userMenuItemMyPassesAndMemberships",
  "userMenuItemMyPaymentMethods",
  "userMenuItemMyPersonalInformation",
  "userMenuItemMyPublicProfile",
  "userMenuItemMyVideos",
  "userMenuItemRegister",
  "userMenuItemSignout",
  "userMenuNewTag",
  "userProfileFavouriteVenuesShowAllButton",
  "userProfileFavouriteVenuesTitle",
  "userProfileFriendNoUpcomingBookings",
  "userProfileFriendsNoFavouriteVenues",
  "userProfileNoFavouriteVenues",
  "userProfileNoUpcomingBookings",
  "userProfileNoUpcomingBookingsLink",
  "userProfilePrivateInformation",
  "userProfileSharingMessage",
  "userProfileUpcomingBookingsShowAllButton",
  "userProfileUpcomingBookingsTitle",
  "userSearchResultsNotFound",
  "venueAmenityChangingRoom",
  "venueAmenityFood",
  "venueAmenityParking",
  "venueAmenityPayWithCard",
  "venueAmenityShopAndRental",
  "venueAmenityShower",
  "venueAmenityWellnessOffer",
  "venueIsInFavorites",
  "venueNewsletterSubscriptionCallToAction",
  "venueNewsletterSubscriptionSubTitle",
  "venueNewsletterSubscriptionSuccess",
  "venueNewsletterSubscriptionTitle",
  "venueProfileAboutTitle",
  "venueProfileAmenitiesTitle",
  "venueProfileBookNow",
  "venueProfileCheapestPrice",
  "venueProfileContentReadLess",
  "venueProfileContentReadMore",
  "venueProfileFrequentlyAskedQuestions",
  "venueProfileFurtherInfoTitle",
  "venueProfileLabelReviewCount",
  "venueProfileLocationTitle",
  "venueProfileMatchPromotionBannerNewTag",
  "venueProfileMatchPromotionBannerSubTitle",
  "venueProfileMatchPromotionBannerTitle",
  "venueProfileMeetTheTeam",
  "venueProfileMetaDescription",
  "venueProfileMetaTitle",
  "venueProfileMoreLocationsTag",
  "venueProfileNoReviewComments",
  "venueProfileOfferedSports",
  "venueProfileOnlineMetaTitle",
  "venueProfileSharingMessage",
  "venueProfileTeamPageTitle",
  "venueProfileTeamTitle",
  "venueProfileTitleReviews",
  "venueProfileTrainersHasMore",
  "venueProfileTrialOfferPrice",
  "venueProfileViewImageGalleryButton",
  "venueTabCamps",
  "venueTabClasses",
  "venueTabCourses",
  "venueTabEducations",
  "venueTabEvents",
  "venueTabMore",
  "venueTabOpenTrainings",
  "venueTabOverview",
  "venueTabPublicMatches",
  "venueTabRetreats",
  "venueTabShop",
  "venueTabSportsFields",
  "venueTabTeam",
  "venueTabTrainings",
  "venueTabVideos",
  "venueTabWorkshops",
  "videosOfferingHeading",
  "viewAll",
  "visibilitySettingDescriptionFriend",
  "visibilitySettingDescriptionPrivate",
  "visibilitySettingDescriptionPublic",
  "visibilitySettingModalApplyButton",
  "visibilitySettingModalDescription",
  "visibilitySettingModalDismissButton",
  "visibilitySettingModalTitle",
  "visibilitySettingTitleFriend",
  "visibilitySettingTitlePrivate",
  "visibilitySettingTitlePublic",
  "whatIsNew",
  "whatIsTheCorporateProgramAnswer",
  "whatIsTheCorporateProgramQuestion",
  "whichVenuesOfferCorporateDiscountsAnswer",
  "whichVenuesOfferCorporateDiscountsQuestion",
  "workshopsOfferingHeading",
  "yearlyRecapActiveDaysSectionSubtitle",
  "yearlyRecapActiveDaysSectionTitle",
  "yearlyRecapActiveDaysSectionTitleText",
  "yearlyRecapActiveDaysSubtitleTier1",
  "yearlyRecapActiveDaysSubtitleTier2",
  "yearlyRecapActiveDaysSubtitleTier3",
  "yearlyRecapActiveDaysSubtitleTier4",
  "yearlyRecapActiveDaysSubtitleTier5",
  "yearlyRecapActiveMinutesSectionTitleText",
  "yearlyRecapBannerSubtitle",
  "yearlyRecapBannerTitle",
  "yearlyRecapFavouriteVenueSubtitle",
  "yearlyRecapMinutesActiveSubtitleTier1",
  "yearlyRecapMinutesActiveSubtitleTier2",
  "yearlyRecapMinutesActiveSubtitleTier3",
  "yearlyRecapMinutesActiveSubtitleTier4",
  "yearlyRecapMinutesActiveSubtitleTier5",
  "yearlyRecapMostActiveMonthSectionSubtitle",
  "yearlyRecapMostActiveMonthSectionTitle",
  "yearlyRecapMostActiveMonthSubtitle",
  "yearlyRecapOnlineBookingsOnlyDisclaimer",
  "yearlyRecapOverviewStreakDescription",
  "yearlyRecapOverviewTitle",
  "yearlyRecapOverviewTopFriendDescription",
  "yearlyRecapOverviewTopSportDescription",
  "yearlyRecapOverviewTopTeacherDescription",
  "yearlyRecapOverviewTopVenueDescription",
  "yearlyRecapOverviewTotalDaysDescription",
  "yearlyRecapOverviewTotalDaysTitle",
  "yearlyRecapOverviewTotalMinutesDescription",
  "yearlyRecapOverviewTotalMinutesTitle",
  "yearlyRecapOverviewTotalSportsDescription",
  "yearlyRecapOverviewTotalSportsTitle",
  "yearlyRecapPageMetaDescription",
  "yearlyRecapPageMetaTitle",
  "yearlyRecapShareButton",
  "yearlyRecapShareSectionButton",
  "yearlyRecapSharingMessage",
  "yearlyRecapSportsCountSubtitleTier1",
  "yearlyRecapSportsCountSubtitleTier2",
  "yearlyRecapSportsCountSubtitleTier3",
  "yearlyRecapSportsCountSubtitleTier4",
  "yearlyRecapSportsCountSubtitleTier5",
  "yearlyRecapSportsSectionSubtitle",
  "yearlyRecapSportsSectionTitle",
  "yearlyRecapStreakSectionTitleText",
  "yearlyRecapStreakSubtitle",
  "yearlyRecapSubtitle",
  "yearlyRecapTitle",
  "yearlyRecapTopFriendSubtitle",
  "yearlyRecapTopSportSectionSubtitle",
  "yearlyRecapTopSportSubtitle",
  "yearlyRecapTopTeacherSubtitle",
  "yearlyRecapTopVenuesSectionSubtitle",
  "yearlyRecapTotalMinutesSectionSubtitle",
  "yearlyRecapTotalMinutesSectionTitle",
  "yearlyRecapYourYear",
  "yearlyRecapYourYearWithEversports",
  "yes",
  "yoga",
  "zirkeltraining",
  "zumba",
], {
  de: () => import("./locales/de.js"),
  en: () => import("./locales/en.js"),
  es: () => import("./locales/es.js"),
  nl: () => import("./locales/nl.js"),
  fr: () => import("./locales/fr.js"),
  it: () => import("./locales/it.js"),
}, "en");
